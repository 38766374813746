(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/assets/javascripts/utils.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/assets/javascripts/utils.js');
"use strict";

var _svs$core$config$data, _svs$core$config$data2;

const isLoggedIn = svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);
const isSelfService = (_svs$core$config$data = (_svs$core$config$data2 = svs.core.config.data.svsconfig) === null || _svs$core$config$data2 === void 0 ? void 0 : _svs$core$config$data2.isSelfService) !== null && _svs$core$config$data !== void 0 ? _svs$core$config$data : false;

const getCustomerId = async () => {
  var _customizedSettingsRe;
  if (!isLoggedIn) {
    return '';
  }
  const customizedSettingsRes = await svs.core.jupiter.callAsync({
    method: 'GET',
    path: '/player/2/customizedsettings'
  });
  const customerId = customizedSettingsRes === null || customizedSettingsRes === void 0 || (_customizedSettingsRe = customizedSettingsRes.response) === null || _customizedSettingsRe === void 0 || (_customizedSettingsRe = _customizedSettingsRe.customizedSettings) === null || _customizedSettingsRe === void 0 || (_customizedSettingsRe = _customizedSettingsRe.properties) === null || _customizedSettingsRe === void 0 ? void 0 : _customizedSettingsRe.customerId;
  if (!customerId) {
    throw new Error('Unable to fetch customerId via customizedSettings.');
  }
  return customerId;
};

const getAuthorizationCode = async () => {
  if (!isLoggedIn) {
    return '';
  }
  const launchParametersRes = await svs.core.jupiter.callAsync({
    method: 'GET',
    path: "/oddset/1/launchparameters"
  });
  const {
    authorizationCode
  } = launchParametersRes.response;
  if (!authorizationCode) {
    throw new Error('Unable to fetch auth code via launchparameters.');
  }
  return authorizationCode;
};

const createParametersObj = _ref => {
  let {
    authorizationCode,
    customerId,
    customerIdPrefix,
    environment
  } = _ref;
  return {
    currency: 'SEK',
    locale: 'sv_SE',
    market: 'SE',
    playerId: isLoggedIn ? "".concat(customerIdPrefix).concat(customerId) : '',
    ticket: isLoggedIn ? authorizationCode : '',
    oddsFormat: 'decimal',
    streamingAllowedForPlayer: 'true',
    channelId: isSelfService ? 7 : undefined,
    retailMode: isSelfService ? true : undefined,
    racingMode: 'false',
    environment 
  };
};
setGlobal('svs.oddset_kambi.utils', {
  createParametersObj,
  getCustomerId,
  getAuthorizationCode
});

 })(window);