(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/assets/javascripts/init-client.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/assets/javascripts/init-client.js');
"use strict";


const {
  createParametersObj,
  getCustomerId,
  getAuthorizationCode
} = svs.oddset_kambi.utils;
const {
  getNotificationsHandler
} = svs.oddset_kambi.components.clientEventsHandler;
const {
  handledErrors
} = svs.oddset_kambi.components.customizedFeedbackHandler.constants;

const initCustomerSettings = helpers => {
  const CustomerSettings = function () {
    this.routeRoot = svs.core.data.baseUrl;
    this.customizedBetslipFeedbackTypes = handledErrors;
  };
  CustomerSettings.prototype.notification = getNotificationsHandler(helpers);
  window.customerSettings = new CustomerSettings();
};

const initClient = async helpers => {
  const {
    customerIdPrefix,
    kambiEnv: environment,
    kambiCdnUrl
  } = svs.oddset_kambi.data;
  const customerId = await getCustomerId();
  const authorizationCode = await getAuthorizationCode();

  window._kc = createParametersObj({
    authorizationCode,
    customerId,
    customerIdPrefix,
    environment
  });
  initCustomerSettings(helpers);
  const script = document.createElement('script'); 
  script.src = kambiCdnUrl;
  script.async = true;
  document.body.appendChild(script);
};
setGlobal('svs.oddset_kambi.initClient', initClient);

 })(window);