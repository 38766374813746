(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/assets/javascripts/router.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/assets/javascripts/router.js');
'use strict';

const isSelfService = svs.utils.ns.isNamespace('svs.core.config.data.svsconfig.isSelfService') && svs.core.config.data.svsconfig.isSelfService;
const {
  useMediaQuery,
  breakpoints
} = svs.components.lbUtils.useMediaQuery;
const {
  NavContextProvider
} = svs.oddset_kambi.components.nav_context;
const {
  BrowserRouter: Router,
  Route,
  Switch
} = ReactRouterDOM;
const {
  pages: {
    PlayPage,
    GameGuidePage,
    WidgetPage
  },
  components: {
    nav_menu: {
      OddsetNavMenu
    }
  }
} = svs.oddset_kambi;
const {
  MenuTabTypes
} = svs.oddset_kambi.components.common.constants;
const getPageComponent = tabType => {
  switch (tabType) {
    case MenuTabTypes.Play:
      return PlayPage;
    case MenuTabTypes.GameGuide:
      return GameGuidePage;
    case MenuTabTypes.Widget:
    default:
      return WidgetPage;
  }
};
const OddsetRouter = () => {
  var _svs$oddset_kambi$dat, _svs, _svs$oddset_kambi$dat2, _svs2;
  const routes = (_svs$oddset_kambi$dat = (_svs = svs) === null || _svs === void 0 || (_svs = _svs.oddset_kambi) === null || _svs === void 0 || (_svs = _svs.data) === null || _svs === void 0 ? void 0 : _svs.tabsConfig) !== null && _svs$oddset_kambi$dat !== void 0 ? _svs$oddset_kambi$dat : [];
  const routePlay = (_svs$oddset_kambi$dat2 = (_svs2 = svs) === null || _svs2 === void 0 || (_svs2 = _svs2.oddset_kambi) === null || _svs2 === void 0 || (_svs2 = _svs2.data) === null || _svs2 === void 0 ? void 0 : _svs2.tabsConfigPlay) !== null && _svs$oddset_kambi$dat2 !== void 0 ? _svs$oddset_kambi$dat2 : {};
  const isSmallScreen = useMediaQuery(breakpoints.down(1080)); 
  const renderMobileMenu = !isSelfService && isSmallScreen;
  const isLoggedIn = svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);
  const classNames = [renderMobileMenu ? 'oddset-bg-layer--mobile' : 'oddset-bg-layer--desktop', isSelfService ? 'oddset-bg-layer--selfservice' : '', isLoggedIn ? 'oddset-bg-layer--logged-in' : ''];
  return React.createElement(Router, {
    basename: svs.core.data.baseUrl
  }, React.createElement(NavContextProvider, null, React.createElement("div", {
    className: "oddset oddset-bg-layer ".concat(classNames.join(' '))
  }, React.createElement(OddsetNavMenu, {
    activeRoutes: routes,
    playRoute: routePlay,
    renderMobileMenu: renderMobileMenu
  }), React.createElement("div", {
    className: "oddset-content".concat(renderMobileMenu ? ' oddset-content--mobile' : '')
  }, React.createElement(Switch, null, routes.filter(route => !route.isPlayRoute).map(route => React.createElement(Route, {
    key: route.path,
    path: "/".concat(route.path),
    render: () => {
      const Component = getPageComponent(route.tabType);
      return React.createElement(Component, {
        widgetAreaIndex: route.widgetArea
      });
    }
  })), React.createElement(Route, {
    key: "playpage",
    path: [routePlay.path, ...routes.filter(route => route.isPlayRoute).map(route => route.path)],
    render: () => React.createElement(PlayPage, null)
  }))), React.createElement("div", {
    id: "KambiBC"
  }))));
};
setGlobal('svs.oddset_kambi.OddsetRouter', OddsetRouter);

 })(window);