(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/assets/javascripts/pages/page-play.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/assets/javascripts/pages/page-play.js');
"use strict";


const {
  useEffect,
  useContext
} = React;
const initClient = svs.oddset_kambi.initClient;
const {
  MaintenanceContext
} = svs.oddset_kambi.components.maintenance_context;
const {
  NavContext
} = svs.oddset_kambi.components.nav_context;
const PlayPage = () => {
  const {
    setIsKambiMaintenance
  } = useContext(MaintenanceContext);
  const {
    setActivePathname
  } = useContext(NavContext);
  useEffect(() => {
    if (!window.hasInitialized) {
      initClient({
        setIsKambiMaintenance,
        setActivePathname
      });
      window.hasInitialized = true;
    }
    const kambiContainerElement = document.querySelector('#KambiBC');
    kambiContainerElement.classList.remove('kambi-hidden');
    return () => {
      kambiContainerElement.classList.add('kambi-hidden');
    };
  }, [setIsKambiMaintenance, setActivePathname]);
  return null; 
};
setGlobal('svs.oddset_kambi.pages.PlayPage', PlayPage);

 })(window);