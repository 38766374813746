(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/assets/javascripts/pages/page-widget.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/assets/javascripts/pages/page-widget.js');
"use strict";


const WidgetArea = svs.components.lbUtils.widgetArea.WidgetArea;
const {
  widgetAreaPrefix
} = svs.oddset_kambi.components.common.constants;
const WidgetPage = _ref => {
  let {
    widgetAreaIndex
  } = _ref;
  if (widgetAreaIndex < 1) {
    return null;
  }
  const widgetArea = "".concat(widgetAreaPrefix, "_").concat(widgetAreaIndex);
  return React.createElement(WidgetArea, {
    widgetArea: widgetArea
  });
};
setGlobal('svs.oddset_kambi.pages.WidgetPage', WidgetPage);

 })(window);