(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/assets/javascripts/index.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/assets/javascripts/index.js');
"use strict";


const OddsetRouter = svs.oddset_kambi.OddsetRouter;
const {
  MaintenanceContextProvider
} = svs.oddset_kambi.components.maintenance_context;
ReactDOM.render(React.createElement(MaintenanceContextProvider, null, React.createElement(OddsetRouter, null)), document.getElementById('oddset-container'));

 })(window);